
import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/services/event-bus';
import Header from '@/components/common/Header.vue';
import Navigation from '@/components/common/Navigation.vue';
import GlobalAlert from '@/components/common/GlobalAlert.vue';
import NewVersionDialog from '@/components/common/NewVersionDialog.vue';
import { overrideDefaultTranslations } from '@/services/translation';
import { sendGALoginEvent } from '@/services/utils';
import { applyClientStylesheet } from '@/services/theme';
import { isProtectedPath } from './services/auth';

@Component({
  components: {
    Header,
    Navigation,
    GlobalAlert,
    NewVersionDialog,
  },
})
export default class Homepage extends Vue {
  public drawer = null;
  private loading = true;
  private isAutomatedBrowser = navigator.webdriver;

  get currentRouteFullPath() {
    return this.$route.fullPath;
  }

  async created(): Promise<void> {
    this.createEventBusListeners();
    await this.initializeStore();
    await this.initializeTranslations();
  }

  get showPage() {
    return !isProtectedPath(this.$route.path) || !this.loading;
  }

  async createEventBusListeners() {
    EventBus.$on('update-token', () => {
      this.$store.commit('auth/retreiveAndUpdateToken');
    });
    EventBus.$on('user-login', async () => {
      await this.initializeStore();
      if (this.$gtag) {
        sendGALoginEvent(
          this.$gtag,
          this.$store.getters['auth/loggedInUser'],
          'Scheme Portal Login',
          process.env.VUE_APP_SCHEME_GOOGLE_ANALYTICS_ID
        );
      }
      if (this.$route.name === 'overview' && this.$route.query.scheme_slug) {
        this.$router.push(`/overview/${this.$route.query.scheme_slug}`);
      }
    });
    EventBus.$on('translations-initialized', async () => {
      document.title = this.$t('login.scheme_portal');
    });
    EventBus.$on('invalid-token', async () => {
      await this.$store.dispatch('auth/logout');
      if (isProtectedPath(this.$route.path)) {
        this.$router.push({ name: 'login' }).catch(() => {
          // ignore routing errors
        });
      }
    });
  }

  async initializeStore() {
    this.loading = true;
    const loggedIn = await this.$store.dispatch('auth/initialize');
    const client = this.$store.getters['auth/clientDetails'];
    applyClientStylesheet(client?.theme_url);
    if (!client) {
      this.$alert('error', 'Invalid hostname, please contact support');
    }

    if (loggedIn) {
      await this.$store.dispatch('clubs/fetchClubs');
    }
    EventBus.$emit('user-initialized');
    this.loading = false;
  }

  /**
   * Merge the two translation files ready for use
   * Main file hosted in locales directory
   * Override file hosted on VUE_APP_TRANSLATION_URL
   * will only contain changes to the original en.json file
   */
  async initializeTranslations() {
    this.loading = true;
    const client = this.$store.getters['auth/clientDetails'];
    const translationUrl = client ? client.translation_url : '';
    const localeMessages = await overrideDefaultTranslations(this.$i18n.locale, this.$i18n.messages, translationUrl);
    this.$i18n.setLocaleMessage(this.$i18n.locale, localeMessages);
    EventBus.$emit('translations-initialized');
    this.loading = false;
  }

  /**
   * Adjusts style by adding classes, based on possible external theme and possible content or route related changes
   */
  get themeStyle(): string | null {
    return this.$store.getters['nav/themeStyle'];
  }
}
