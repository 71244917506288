import { Breadcrumb } from '@/models/breadcrumb';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { Location, Route } from 'vue-router';
import { memberRoute, schemeRoute, planningRoute } from '@/router/coach';
import { SchemeNodeModel } from '@/models/schemeNodeModel';
import { SchemeModel } from '@/models/schemeModel';
import { filterTree, flatMapTree } from '@/services/utils';

/**
 * List state values for route titles that need to be displayed and
 * remembered for routing and tab navigation
 *
 * Should only be updated via mutations defined below.
 *
 * @property state - NavState
 */
const getDefaultState = () => ({
  memberTabLastVisitedRoute: '',
  schemeTabLastVisitedRoute: '',
  planningTabLastVisitedRoute: '',
  memberBreadcrumbs: [] as Array<Breadcrumb>,
  schemeBreadcrumbs: [] as Array<Breadcrumb>,
  planningBreadcrumbs: [] as Array<Breadcrumb>,
  currentGroupName: '',
  currentGroupIsSlot: false,
  currentMemberName: '',
  schemeSelectedSkills: [] as Array<string>,
  downloadVideos: false,
  themeStyle: 'default', // TODO: hidden until theme loaded?
});

const state = getDefaultState();

type NavState = typeof state;

/**
 * Vuex store mutations available for updating state values above.
 * These should be the only way state values are updated.
 * Should be synchronous transactions only to ensure predictability of state.
 *
 * @property mutations - Object
 */
const mutations = {
  resetState: (moduleState: NavState) => {
    const theme = moduleState.themeStyle;
    Object.assign(moduleState, getDefaultState());
    moduleState.themeStyle = theme; // Don't change theme when logging out
  },
  updateMemberTabLastVisitedRoute: (moduleState: NavState, currentRoute: any) => {
    if (!currentRoute) {
      currentRoute = memberRoute;
    }
    moduleState.memberTabLastVisitedRoute = currentRoute;
  },

  updatePlanningTabLastVisitedRoute: (moduleState: NavState, currentRoute: any) => {
    if (!currentRoute) {
      currentRoute = planningRoute;
    }
    moduleState.planningTabLastVisitedRoute = currentRoute;
  },

  updateSchemeTabLastVisitedRoute: (moduleState: NavState, currentRoute: any) => {
    if (!currentRoute) {
      currentRoute = schemeRoute;
    }
    moduleState.schemeTabLastVisitedRoute = currentRoute;
  },

  /**
   * Updates the stores breadcrumb array.
   * @param moduleState - state from this module above
   * @param {Array<Breadcrumb>} crumbs.
   * @return {void}
   */
  updateMemberBreadcrumbs: (moduleState: NavState, crumbs: Array<Breadcrumb>): void => {
    moduleState.memberBreadcrumbs = crumbs;
  },

  /**
   * Updates the stores breadcrumb array.
   * @param moduleState - state from this module above
   * @param {Array<Breadcrumb>} crumbs.
   * @return {void}
   */
  updatePlanningBreadcrumbs: (moduleState: NavState, crumbs: Array<Breadcrumb>): void => {
    moduleState.planningBreadcrumbs = crumbs;
  },

  /**
   * Updates the stores breadcrumb array.
   *
   * @param moduleState - state from this module above
   * @param {Array<SchemeNodeModel>} crumbs.
   * @return {void}
   */
  updateSchemeBreadcrumbs: function(moduleState: NavState, crumbs: Array<Breadcrumb>) {
    moduleState.schemeBreadcrumbs = crumbs;
  },

  /**
   * Change the style of the whole app when the content
   * matches certain parameters
   *
   * @param {state} moduleState
   * @param {string} styleName
   */
  updateThemeStyle: (moduleState: NavState, styleName: string): void => {
    moduleState.themeStyle = styleName;
  },

  updateSchemeSelectedSkills: function(moduleState: NavState, schemeIds: Array<string>) {
    moduleState.schemeSelectedSkills = schemeIds;
  },

  updateSchemeDownloadVideos: function(moduleState: NavState, value: boolean) {
    moduleState.downloadVideos = value;
  },

  /**
   * Updates the current group name
   * @param moduleState
   * @param currentGroupName
   */
  updateGroupName: (moduleState: NavState, currentGroupName: string): void => {
    moduleState.currentGroupName = currentGroupName;
  },

  updateGroupIsSlot: (moduleState: NavState, isSlot: boolean): void => {
    moduleState.currentGroupIsSlot = isSlot;
  },
};

/**
 * Available functions for handling business logic relating to this store modules state properties.
 * Can be asynchronous.
 * Interacts with the modules state properties via committing one or more synchronous mutations.
 *
 * @property actions - Object
 */
const actions = {
  resetState: async (context: ActionContext<NavState, RootState>): Promise<void> => {
    context.commit('resetState');
  },
  /**
   * Updates the breadcrumbs which it gets from the meta in the route.
   *
   * @param {ActionContext} context
   * @param {Route} currentRoute
   */
  updateMemberBreadcrumbs: async (context: ActionContext<NavState, RootState>, currentRoute: Route) => {
    const routeParams = currentRoute.params as { groupId: string };
    const groupId = typeof routeParams.groupId !== 'undefined' ? parseInt(routeParams.groupId, 10) : 0;
    const breadcrumbs: Array<Breadcrumb> = Array.from(currentRoute.meta?.breadcrumbs);
    const replacementBreadcrumbs = [] as Array<Breadcrumb>;

    if (groupId > 0) {
      context.dispatch('updateGroupNameById', groupId).then(() => {
        const groupName = context.state.currentGroupName || '';
        breadcrumbs.forEach((crumb: Breadcrumb) => {
          const regex = /:groupId/gi;
          const crumbLocation = crumb.to as Location;
          const crumbText = crumb.text.replace(regex, groupName);

          // Need different breadcrumb text for slots
          if (crumb.i18nText?.startsWith('breadcrumb.assess_by')) {
            crumb.i18nText = context.state.currentGroupIsSlot
              ? 'breadcrumb.assess_by_pupil'
              : 'breadcrumb.assess_by_member';
          }

          const replacementBreadcrumb = {
            text: crumbText,
            i18nText: crumb.i18nText,
            to: { path: crumbLocation.path?.replace(regex, groupId.toString()) },
            disabled: crumb.disabled,
            exact: true,
          };
          replacementBreadcrumbs.push(replacementBreadcrumb);
        });
      });
    } else {
      breadcrumbs.forEach((crumb: Breadcrumb) => {
        const crumbLocation = crumb.to as Location;
        const replacementBreadcrumb = {
          text: crumb.text,
          i18nText: crumb.i18nText,
          to: crumbLocation,
          disabled: crumb.disabled,
          exact: crumb.exact,
        };
        replacementBreadcrumbs.push(replacementBreadcrumb);
      });
    }
    context.commit('updateMemberBreadcrumbs', replacementBreadcrumbs);
  },

  updatePlanningBreadcrumbs: async (
    context: ActionContext<NavState, RootState>,
    nodeOptions: { schemeSlug: string; lessonPlanUuid: string; lessonPlanGroupUuid: string }
  ) => {
    const { schemeSlug } = nodeOptions;

    let scheme = null;
    const schemes = context.rootGetters['schemes/schemeList'];
    if (schemeSlug) {
      scheme = schemes.find((s: SchemeModel) => s.slug === schemeSlug);
    }

    const breadcrumbs: Array<Breadcrumb> = [
      {
        text: 'planning',
        i18nText: 'nav.planning',
        to: { path: planningRoute },
        disabled: schemes.length <= 1,
        exact: true,
      },
    ];

    if (scheme && schemes.length > 1) {
      breadcrumbs.push({
        text: scheme.name,
        to: { name: 'lesson-plans', params: { schemeSlug } },
        exact: true,
      });
    }

    context.commit('updatePlanningBreadcrumbs', breadcrumbs);
  },

  /**
   * Updates the stores breadcrumb cache.
   *
   * @param context - Vuex ActionContext
   * @param {SchemeNodeModel} schemeNodeId.
   * @return {void}
   */
  updateSchemeBreadcrumbs: (
    context: ActionContext<NavState, RootState>,
    nodeOptions: { schemeNodeId: string; schemeSlug: string }
  ) => {
    const { schemeSlug } = nodeOptions;
    const schemeNodeId = nodeOptions.schemeNodeId || '/';

    let scheme = null;
    const schemes = context.rootGetters['schemes/schemeList'];
    if (schemeSlug) {
      scheme = schemes.find((s: SchemeModel) => s.slug === schemeSlug);
    }

    // Scheme list
    const breadcrumbs: Array<Breadcrumb> = [
      {
        text: 'Scheme',
        i18nText: 'nav.scheme',
        to: schemeRoute,
        disabled: schemes.length <= 1,
        exact: true,
      },
    ];

    if (scheme && schemes.length > 1) {
      // Scheme root
      breadcrumbs.push({
        text: scheme.name,
        to: { name: 'scheme-page', params: { schemeSlug } },
        disabled: !schemeNodeId,
        exact: true,
      });
    }

    if (schemeNodeId) {
      const schemeRoot = context.rootGetters['schemes/getScheme'](schemeSlug);
      const nodesInPath = filterTree(schemeRoot, n => n.id === schemeNodeId);
      breadcrumbs.push(
        ...flatMapTree(nodesInPath, n => ({
          text: n.title,
          to: `${schemeRoute}/${schemeSlug}${n.id}`,
          disabled: n.children.length === 0,
          exact: true,
        }))
      );
    }
    context.commit('updateSchemeBreadcrumbs', breadcrumbs);
  },

  /**
   * Calculates the required style based on content!!
   *
   * Dialog boxes don't need to update the theme for the whole site so
   * this is just used to calculate the style that they need and return it
   *
   * @param context - Vuex ActionContext
   * @param data { schemeNodeId: string; updatePopup: boolean }
   * @return {void}
   */
  calculateStyle: (
    context: ActionContext<NavState, RootState>,
    data: { schemeNodeId: string; schemeSlug: string; updateTheme: boolean }
  ): string | null => {
    // if themeStyle is null then the attribute class won't be rendered
    const client = context.rootGetters['auth/clientDetails'];
    let themeStyle = client ? client.theme_name : 'default';
    if (themeStyle === 'default' && data.schemeSlug) {
      // Check for scheme theme
      const scheme = context.rootGetters['schemes/getScheme'](data.schemeSlug);
      if (!scheme) {
        return themeStyle;
      }
      if (scheme.theme) {
        themeStyle = scheme.theme + '-scheme';
      }
    }

    if (themeStyle && data.schemeNodeId && data.schemeSlug) {
      // get the group from the node and use it as the style css class
      const node = context.rootGetters['schemes/getNode'](data.schemeSlug, data.schemeNodeId) as SchemeNodeModel;
      let styleGroups = '';
      if (node.group) {
        styleGroups = 'group-' + node.group;
      }

      let parentNode = context.rootGetters['schemes/getNode'](data.schemeSlug, node.parentId) as SchemeNodeModel;
      // keep looping through parent nodes until get to beginning
      while (parentNode.parentId) {
        if (parentNode.group) {
          styleGroups = 'group-' + parentNode.group + ' ' + styleGroups;
        }
        parentNode = context.rootGetters['schemes/getNode'](data.schemeSlug, parentNode.parentId) as SchemeNodeModel;
      }

      // append the new style class to the original themeStyle class
      if (styleGroups.length > 0) {
        themeStyle = themeStyle + ' ' + styleGroups;
      }
    }
    // update theme if requested
    if (data.updateTheme) {
      // whether its null or something update it
      context.commit('updateThemeStyle', themeStyle);
    }
    // popup dialog just needs this setting once when its created
    return themeStyle;
  },

  /**
   * Update current selected group's name
   * Group name could be emtpy string so doesn't break the UI
   *
   * @param {ActionContext<ListState, RootState>} context
   * @param {string} groupId
   * @return {Group} groupName
   */
  updateGroupNameById: async (context: ActionContext<NavState, RootState>, groupId: number) => {
    const group = await context.dispatch('groups/getGroupFromStoreDb', groupId, { root: true });
    if (!group || !group.name) {
      return false;
    }
    context.commit('updateGroupName', group.name);
    context.commit('updateGroupIsSlot', group ? group.is_slot : false);
    return group;
  },
};

/**
 * Available functions for code external to the store to retrieved this modules state properties values.
 * Can alter, calculate with or filter these values before return.
 *
 * @property getters - Object
 */
const getters = {
  memberTabLastVisitedRoute: (moduleState: NavState) => moduleState.memberTabLastVisitedRoute,
  schemeTabLastVisitedRoute: (moduleState: NavState) => moduleState.schemeTabLastVisitedRoute,
  planningTabLastVisitedRoute: (moduleState: NavState) => moduleState.planningTabLastVisitedRoute,
  memberBreadcrumbs: (moduleState: NavState) => moduleState.memberBreadcrumbs,
  schemeBreadcrumbs: (moduleState: NavState) => moduleState.schemeBreadcrumbs,
  planningBreadcrumbs: (moduleState: NavState) => moduleState.planningBreadcrumbs,
  groupName: (moduleState: NavState) => moduleState.currentGroupName,
  schemeSelectedSkills: (moduleState: NavState) => moduleState.schemeSelectedSkills,
  downloadVideos: (moduleState: NavState) => moduleState.downloadVideos,
  // returning null so that attribute class isn't rendered if its not set to something
  themeStyle: (moduleState: NavState) =>
    moduleState.themeStyle && moduleState.themeStyle.length > 0 ? moduleState.themeStyle : null,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
