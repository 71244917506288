
import { Component } from 'vue-property-decorator';
import { AppIds } from '@/consts';
import { EventBus } from '@/services/event-bus';
import { mixins } from 'vue-class-component';
import { Online } from '@/components/mixins/online';

@Component
export default class HeaderComponent extends mixins(Online) {
  private requestQueueLength = 0;

  get appId() {
    return this.$store.getters.appId as AppIds;
  }

  get isLoggedIn() {
    return this.$store.getters['auth/isLoggedIn']();
  }

  async getQueuedRequests() {
    if (this.appId === 'coach' && navigator.serviceWorker) {
      if (this.requestQueueLength > 0) {
        this.$alert('warning', this.$t('offline.out_of_date', { cnt: this.requestQueueLength }));
      } else {
        this.$alert('success', this.$t('offline.up_to_date'));
      }
    }
  }

  mounted() {
    EventBus.$on('request-queue-length', (len: number) => {
      this.requestQueueLength = len;
    });
  }

  reset() {
    // Since parent portal is all one page, this is used to reset to the home page state
    EventBus.$emit('reset');
  }

  get onlineStatus() {
    if (this.online) {
      if (this.requestQueueLength) {
        return 'status-syncing';
      }
      return 'status-online';
    }
    return 'status-offline';
  }

  get isOrganisationPortal() {
    return this.$store.getters['auth/isOrganisationPortal'];
  }
}
